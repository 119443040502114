<template>
	<div>
    <v-container fluid>
      <div class="novel-display container">
		    <pdf
		    	
		    	:src="src"
		    	:page="currentPage"
		    	style="display: inline-block; width: 100%"
		    ></pdf>
	    </div>
      <div class="text-center">
        <v-pagination 
			v-scroll="onScroll"
			v-model="currentPage" 
			:length="numPages" 
			circle 
			total-visible="1"
			@next="changePagination"
			@previous="changePagination"
		></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>

import pdf from 'vue-pdf'

var loadingTask = pdf.createLoadingTask('/Aviso de privacidad el Valle de las Bendiciones.pdf');

export default {
	components: {
		pdf
	},
	data() {
		return {
			src: loadingTask,
			numPages: undefined,
      currentPage: 1
		}
	},
	mounted() {
		this.src.promise.then(pdf => {
			this.numPages = pdf.numPages;
		});
	},

	methods: {
		changePagination (){
			this.$vuetify.goTo(0)
		},
		onScroll (e) {
    	  if (typeof window === 'undefined') return
    	  const top = window.pageYOffset ||   e.target.scrollTop || 0
    	  this.fab = top > 20
    	}
	}
}

</script>

<style scoped>
  /deep/ .v-pagination__item{
    display: none;
  }
  /deep/ .v-pagination__more{
    display: none;
  }
</style>